export const mainBgColor = '#231f20'; // Black
export const headerBgColor = '#161414';
export const primaryTextColor = '#fff';
export const contentTextColor = '#181616';
export const highlightColor = '#ff921e'; // Orange
export const tanColor = '#d9d6c3'; // Tan
export const lightGrayColor = '#dadada'; // Light Gray
export const highlightForegroundColor = '#231F20'; // Dark Gray
export const typekitUrl = 'https://use.typekit.net/als5mhq.css';
export const volunteerUrl = 'https://forms.gle/G8VQG6Po63EadNWX8';
export const primaryFontFamily = 'acumin-pro-wide, sans-serif';
export const fadeAnimationDuration = 0.2;
export const colorTransition = 'color linear 150ms';
