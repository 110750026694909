import { useStaticQuery, graphql } from 'gatsby';
import { SvgImage } from '../interfaces';

export const useCompactLogo = (): SvgImage => {
  return useLogos().compact;
};

export const useFullLogo = (): SvgImage => {
  return useLogos().full;
};

export const useLogos = (): {
  full: SvgImage;
  compact: SvgImage;
} => {
  const data = useStaticQuery(graphql`
    query {
      full: file(name: { eq: "logo" }) {
        publicURL
      }

      compact: file(name: { eq: "logo-compact" }) {
        publicURL
      }
    }
  `);

  return data;
};
