import { useStaticQuery, graphql } from 'gatsby';

export const useFonts = () => {
  const data = useStaticQuery(graphql`
    query {
      vtcmarsha: file(name: { eq: "VTCMarsha" }) {
        publicURL
      }
    }
  `);
  return data;
};
