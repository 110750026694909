import { useStaticQuery, graphql } from 'gatsby';
import { SvgImage } from '../interfaces';

export const useIcons = (): {
  podcast: SvgImage;
  destroyed: SvgImage;
  funding: SvgImage;
  injustice: SvgImage;
  influence: SvgImage;
  facebook: SvgImage;
  twitter: SvgImage;
  instagram: SvgImage;
  youtube: SvgImage;
  hamburger: SvgImage;
  close: SvgImage;
  piechart: SvgImage;
  download: SvgImage;
  instagramFooter: SvgImage;
} => {
  const data = useStaticQuery(graphql`
    query {
      close: file(name: { eq: "close" }) {
        publicURL
      }

      podcast: file(name: { eq: "icon-podcast" }) {
        publicURL
      }

      destroyed: file(name: { eq: "icon-destroyed" }) {
        publicURL
      }

      download: file(name: { eq: "icon-download" }) {
        publicURL
      }

      funding: file(name: { eq: "icon-funding" }) {
        publicURL
      }

      hamburger: file(name: { eq: "icon-hamburger" }) {
        publicURL
      }

      injustice: file(name: { eq: "icon-injustice" }) {
        publicURL
      }

      influence: file(name: { eq: "icon-influence" }) {
        publicURL
      }

      link: file(name: { eq: "icon-link" }) {
        publicURL
      }

      piechart: file(name: { eq: "icon-piechart" }) {
        publicURL
      }

      facebook: file(name: { eq: "icon-facebook" }) {
        publicURL
      }

      twitter: file(name: { eq: "icon-twitter" }) {
        publicURL
      }

      instagram: file(name: { eq: "icon-instagram" }) {
        publicURL
      }

      youtube: file(name: { eq: "icon-youtube" }) {
        publicURL
      }

      instagramFooter: file(name: { eq: "instagram-footer" }) {
        publicURL
      }
    }
  `);

  return data;
};
