import React from 'react';
import { Global, css } from '@emotion/core';
import * as constants from '../constants';
import { Helmet } from 'react-helmet';
import { useFonts } from '../hooks/fonts';
import normalize from 'normalize.css';
import vtcmarsha_woff from '../fonts/VTCMarsha-Bold.woff';
import vtcmarsha_woff2 from '../fonts/VTCMarsha-Bold.woff2';

const GlobalStyles: React.FC = () => {
  const Fonts = useFonts();

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href={constants.typekitUrl}></link>
      </Helmet>
      <Global
        styles={css`
          ${normalize}

          @font-face {
            font-family: VTCMarsha;
            font-weight: 700;
            font-style: normal;
            src: url(${vtcmarsha_woff}) format('woff2'),
              url(${vtcmarsha_woff2}) format('woff');
            font-display: swap;
          }
          * {
            color: inherit;
            font: inherit;
            line-height: 1;
            box-sizing: border-box;
          }

          body {
            background-color: ${constants.mainBgColor};
            color: ${constants.primaryTextColor};
            font-family: ${constants.primaryFontFamily};
            -webkit-font-smoothing: antialiased;

            &.menu-open {
              overflow: hidden;
            }
          }

          strong {
            font-weight: 900;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          p {
            margin-top: 0;
          }

          h2 {
            font-size: 72px;
            line-height: 74px;
            font-weight: 900;
          }

          h3 {
            font-size: 36px;
            line-height: 42px;
            font-weight: 900;

            @media (max-width: 767.98px) {
              font-size: 20px;
              line-height: 1.375;
            }
          }

          h4 {
            font-size: 24px;
            line-height: 36px;
          }

          h5,
          .subheader {
            font-size: 21px;
            line-height: 28px;

            &.uppercase {
              text-transform: uppercase;
            }
          }

          input[type='text'],
          input[type='email'],
          input[type='button'] {
            -webkit-appearance: none;
            -webkit-border-radius: 0;
          }

          p {
            margin-bottom: 1rem;
            line-height: 1.375;
          }

          select {
            color: ${constants.highlightForegroundColor};
          }

          details {
            max-width: 800px;
            margin: 0 auto;
            padding: 2rem 0;
            border-bottom: 3px solid ${constants.contentTextColor};

            &:first-of-type {
              border-top: 3px solid ${constants.contentTextColor};
            }

            > summary {
              position: relative;
              font-weight: bold;
              font-size: 18px;
              line-height: 28px;
              padding: 0 36px;
              vertical-align: baseline;

              &:hover {
                cursor: pointer;
              }

              &:focus {
                outline: none;
              }

              &::-webkit-details-marker {
                position: absolute;
                top: 0;
                left: 0.5rem;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='17px' height='28px' viewBox='0 0 17 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3ePath Copy 11%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cpolygon id='Path-Copy-11' fill='%23FF921E' fill-rule='nonzero' points='3.77782764 0 17 14 3.77782764 28 0 23.9999321 9.44456911 13.9997624 0 3.9995927'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                background-size: 17px 28px;
                background-repeat: no-repeat;
                width: 17px;
                height: 28px;
                color: transparent;
                transition: transform linear 150ms;
              }

              &::marker {
                color: ${constants.highlightColor};
              }
            }

            &[open] {
              summary {
                margin-bottom: 1rem;
              }

              summary::-webkit-details-marker {
                transform: rotate(90deg);
              }
            }

            > div {
              padding: 0 calc(28px + 0.5rem) 0 calc(28px + 0.5rem);
              font-size: 16px;
              line-height: 22px;
            }
          }

          .container {
            max-width: 1184px;
            margin: 0 auto;
          }

          .text-lg {
            font-size: 18px;
            color: #231f20;
            line-height: 28px;
          }

          .text-sm {
            font-size: 15px;
            line-height: 18px;
          }

          .color-orange {
            color: ${constants.highlightColor};
          }

          a.phone-link {
            color: #e57600;
            text-decoration: none;
            transition: ${constants.colorTransition};

            &:hover {
              color: ${constants.highlightForegroundColor};
            }
          }

          .visually-hidden {
            // Source: https://github.com/twbs/bootstrap/blob/cdea25584dc7a074d099931c5be77fe3fb5fde20/scss/mixins/_visually-hidden.scss
            position: absolute !important;
            width: 1px !important;
            height: 1px !important;
            padding: 0 !important;
            margin: -1px !important;
            overflow: hidden !important;
            clip: rect(0, 0, 0, 0) !important;
            white-space: nowrap !important;
            border: 0 !important;
          }

          .display-none {
            display: none !important;
          }

          ul.ul-icon {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              margin-bottom: 1em;
              font-size: 21px;
              line-height: 28px;

              &:last-of-type {
                margin-bottom: 0;
              }

              @media (max-width: 767.98px) {
                font-size: 15px;
                color: #181616;
                letter-spacing: 0;
                line-height: 20px;
              }
            }

            &.li-close {
              > li {
                padding-left: 51px;
                padding-left: 51px;
                background-repeat: no-repeat;
                background-position: 0 7px;
                background-size: 36px 27px;
                min-height: 35px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17'%3E%3Cpolygon fill='%23FF921E' fill-rule='nonzero' points='5.25 8.49 0 3.22 3.22 0 8.49 5.25 13.76 0 17 3.22 11.73 8.49 17 13.76 13.76 17 8.49 11.73 3.22 17 0 13.76'/%3E%3C/svg%3E");
              }
            }

            &.li-check {
              > li {
                padding-left: 51px;
                background-repeat: no-repeat;
                background-position: 0 7px;
                background-size: 33px 26px;
                min-height: 35px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33 26'%3E%3Cpolygon fill='%23FF921E' fill-rule='evenodd' points='11.31 17.12 4.47 10.31 0 14.75 11.31 26 11.31 26 33 4.44 28.53 0'/%3E%3C/svg%3E");
              }
            }

            &.li-arrow {
              > li {
                padding-left: 51px;
                background-repeat: no-repeat;
                background-position: 0 7px;
                background-size: 36px 27px;
                min-height: 35px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 27'%3E%3Cpath fill='%23FF921E' fill-rule='evenodd' d='M22.5 0L18 4.5l6 6H0v5.97s15.81.04 24 .03l-6 6 4.5 4.5L36 13.5 22.5 0z'/%3E%3C/svg%3E");
              }
            }
          }

          .select2-results__option {
            color: #231f20;
          }

          .select2-search--dropdown .select2-search__field {
            color: #231f20;
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
